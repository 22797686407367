export function getCookie(name:string):string|undefined {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) {
        return parts.pop()!.split(";").shift();
    } else {
        return '';
    }
}

export function getCSRFToken():string  {
    return getCookie('csrftoken')!;
}